import { useState } from 'react';
import { Navbar, Sidebar } from './';
import { Outlet } from "react-router-dom";

function DashboardLayout() {

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    return (
        <>
            <Sidebar isOpen={isSidebarOpen} closeSidebar={() => setSidebarOpen(false)} />
            {isSidebarOpen && <div className="blur-screen" />}
            <div className='wrapper'>
                <Navbar openSidebar={() => setSidebarOpen(!isSidebarOpen)} />
                <div className="p-2">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default DashboardLayout;