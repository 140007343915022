import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import { LoadingScreen, Alert } from "../../components";
import { FaPlus } from "react-icons/fa";
import { getRideTypes } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreateRideTypeModal } from '.';

function RideType() {

    // Get Currency list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const rideTypesCall = useCallback(() => getRideTypes(requestParams), [requestParams]);
    const [rideTypes, typesError, typesAreLoading, reload] = useGetFromAPI(rideTypesCall);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    return (
        <Container>
            {
                typesError
                &&
                <Alert message={typesError.message} />
            }
            <Card>
                {
                    typesAreLoading
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Tipos de Vueltas</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !typesAreLoading && !typesError
                                    &&
                                    rideTypes.map((type) => (
                                        <tr key={type.id}>
                                            <td>{type.id}</td>
                                            <td>{type.name}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !typesAreLoading && !typesError
                            &&
                            <Pagination className="m-0">
                                {
                                    rideTypes.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{rideTypes.current_page}</Pagination.Item>
                                {
                                    rideTypes.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: rideTypes.last_page })}>
                                            {rideTypes.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreateRideTypeModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                reload={reload}
            />
        </Container>
    );
}

export default RideType;