import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingScreen, Alert } from "../../components";
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus, FaSearch, FaSyncAlt } from "react-icons/fa";
import { RiEditBoxLine } from "react-icons/ri";
import { MdInfoOutline } from "react-icons/md";
import { getClients, getClientsMemberships } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreateClientModal, EditClientModal, ClientDebtHistory } from './';

function Client() {

    // Get Memberships list
    const getNewMemberships = useCallback(() => getClientsMemberships(), []);
    const [memberships, membershipError, membershipsAreLoading] = useGetFromAPI(getNewMemberships);

    // Get Clients list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const getNewClients = useCallback(() => getClients(requestParams), [requestParams]);
    const [clients, clientsError, clientsAreLoading, reload] = useGetFromAPI(getNewClients);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    const [userSelected, setUserSelected] = useState({});

    // Toggle Edit Modal
    const [showEditModal, setShowEditModal] = useState(false);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = (client) => {
        setShowEditModal(true);
        setUserSelected(client);
    };

    // Toggle History Modal
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const handleCloseHistoryModal = () => setShowHistoryModal(false);
    const handleShowHistoryModal = (client) => {
        setShowHistoryModal(true);
        setUserSelected(client);
    };

    const filterClients = (event) => {
        event.preventDefault();
        const searchPhoneNumber = event.target.searchPhoneNumber.value;
        const searchName = event.target.searchName.value;
        const searchMembership = event.target.searchMembership.value;
        const newParams = {};
        if (searchPhoneNumber) {
            newParams.phone_number = searchPhoneNumber;
        }
        if (searchName) {
            newParams.name = searchName;
        }
        if (searchMembership) {
            newParams.membership = searchMembership;
        }
        setRequestParams({ ...requestParams, ...newParams })
    }

    const reloadFilters = () => {
        setRequestParams({
            page: requestParams.page
        });
    }



    return (
        <Container>
            {
                clientsError
                &&
                <Alert message={clientsError.message} />
            }
            <Card>
                {
                    (clientsAreLoading || membershipsAreLoading)
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Clientes</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <Row
                        as="form"
                        className="align-items-end justify-content-between gap-3 mb-4"
                        onSubmit={filterClients}
                    >
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Nombre</BtForm.Label>
                                <BtForm.Control type="text" name="searchName" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Teléfono</BtForm.Label>
                                <BtForm.Control type="text" name="searchPhoneNumber" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Membresía</BtForm.Label>
                                <BtForm.Select name="searchMembership">
                                    <option value=''> ... </option>
                                    {
                                        !membershipsAreLoading
                                        &&
                                        !membershipError
                                        &&
                                        memberships.map(({ id, name }) => (
                                            <option key={id} value={id}> {name} </option>
                                        ))
                                    }
                                </BtForm.Select>
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" className="flex-center">
                            <Button type="submit" variant="primary" className="btn-icon px-4 ms-auto">
                                <span>Buscar</span>
                                <FaSearch />
                            </Button>
                            <Button type="button" variant="primary" className="btn-icon ms-auto" onClick={reloadFilters}>
                                <span>Reiniciar</span>
                                <FaSyncAlt />
                            </Button>
                        </Col>
                    </Row>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Teléfono</th>
                                    <th>Dirección</th>
                                    <th>Membresía</th>
                                    <th>Balance</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !clientsAreLoading && !clientsError
                                    &&
                                    clients.data.map((client) => (
                                        <tr key={client.id}>
                                            <td>{client.id}</td>
                                            <td>{client.name}</td>
                                            <td>{client.last_name}</td>
                                            <td>{client.phone_number}</td>
                                            <td>{client.address}</td>
                                            <td>{client.client_membership.name}</td>
                                            <td>{client.balance}</td>
                                            <td>
                                                <div className="d-flex gap-2">
                                                    <RiEditBoxLine
                                                        onClick={() => handleShowEditModal(client)}
                                                        className="text-primary c-pointer"
                                                        size={22.5}
                                                    />
                                                    {
                                                        client?.debtHistory
                                                        &&
                                                        <MdInfoOutline
                                                            onClick={() => handleShowHistoryModal(client)}
                                                            className="text-info c-pointer"
                                                            size={22.5}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !clientsAreLoading && !clientsError
                            &&
                            <Pagination className="m-0">
                                {
                                    clients.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{clients.current_page}</Pagination.Item>
                                {
                                    clients.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: clients.last_page })}>
                                            {clients.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreateClientModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                memberships={memberships}
                reload={reload}
            />
            <EditClientModal
                showModal={showEditModal}
                closeModal={handleCloseEditModal}
                memberships={memberships}
                client={userSelected}
                reload={reload}
            />
            <ClientDebtHistory
                showModal={showHistoryModal}
                closeModal={handleCloseHistoryModal}
                client={userSelected}
            />
        </Container>
    );
}

export default Client;