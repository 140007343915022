import './Alert.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MdWarningAmber } from "react-icons/md";
import { useState } from 'react';

function Alert({ message }) {

    const [showModal, setShowModal] = useState(true);
    const [customClass, setCustomClass] = useState('');
    const handleCloseModal = () => {
        setCustomClass('scale-down-center')
        setTimeout(() => {
            setShowModal(false)
        }, 50)
    };


    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            animation={false}
            centered
            contentClassName={`scale-up-center ${customClass}`}
        >
            <Modal.Body>
                <div className='w-100 flex-center text-danger mb-3'>
                    <MdWarningAmber size={100} />
                </div>
                <p className="w-100 display-6 text-center">
                    {message}
                </p>
            </Modal.Body>
            <Modal.Footer className="flex-center p-2">
                <Button
                    variant="secondary"
                    className='px-4'
                    onClick={handleCloseModal}
                >
                    Entiendo
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Alert;