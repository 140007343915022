import { AuthContext } from './';
import { useContext } from "react";

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(
            'Error no auth context'
        );
    }
    return context;
}

export default useAuthContext;