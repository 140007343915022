import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { updateCurrency } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function EditCurrencyModal({ showModal, closeModal, currency, reload }) {

    const defaultValues = {
        change: currency?.change
    }

    //Store a currency
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const updatedCurrency = {
            id: currency.id,
            change: values.change,
        }
        const [response, error] = await updateCurrency(updatedCurrency);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const validationSchema = Yup.object().shape({
        change: Yup.string()
            .min(1, '* Muy corto!')
            .max(100, '* Muy Largo!')
            .required('* Requerido'),
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Moneda</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <BtForm.Group className="mb-3">
                                <BtForm.Label>
                                    Nombre
                                </BtForm.Label>
                                <BtForm.Control
                                    type="text"
                                    disabled
                                    value={currency?.name}
                                />
                            </BtForm.Group>
                            <BtForm.Group className="mb-3">
                                <BtForm.Label htmlFor="change">
                                    Cambio
                                </BtForm.Label>
                                <BtForm.Control
                                    type="text"
                                    id='change'
                                    name="change"
                                    placeholder="..."
                                    onChange={handleChange}
                                    isInvalid={errors.change}
                                    defaultValue={defaultValues.change}
                                />
                                <BtForm.Text className="text-muted">
                                    Solo se usaran 2 decimales
                                </BtForm.Text>
                                <BtForm.Control.Feedback type="invalid" className="error-msg">
                                    {errors.change}
                                </BtForm.Control.Feedback>
                            </BtForm.Group>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default EditCurrencyModal;