import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";

function InfoBillModal({ showModal, closeModal, bill }) {

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            size='lg'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Vueltas de la factura # {bill?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Cliente</th>
                            <th>Empleado</th>
                            <th>Tipo de Vuelta</th>
                            <th>Desde</th>
                            <th>Hasta</th>
                            <th>Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bill?.rides.map((ride) => (
                                <tr key={ride.id}>
                                    <td>{ride.id}</td>
                                    <td>{ride.client.name}  {ride.client.last_name}</td>
                                    <td>{ride.employee.name}  {ride.employee.last_name}</td>
                                    <td>{ride.ride_type.name}</td>
                                    <td>{ride.from}</td>
                                    <td>{ride.to}</td>
                                    <td>{ride.amount}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

export default InfoBillModal;