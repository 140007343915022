import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts';

const useGetFromAPI = (request) => {
    const { authDispatch } = useAuthContext();
    const [requestData, setRequestData] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reloadData, setReloadData] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async (fetchRequest) => {
            try {
                const response = await fetchRequest();
                if (isMounted) {
                    setRequestData(response.data);
                    setFetchError(null);
                }
            } catch (err) {
                if (isMounted) {
                    if (err.response?.status === 401) {
                        authDispatch({ type: 'LOGOUT' });
                    }
                    if (err.response?.data) {
                        setFetchError(err.response?.data);
                    } else {
                        setFetchError('Ups algo salio mal.');
                    }
                    setRequestData(null);
                }
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        fetchData(request);

        const cleanUp = () => {
            isMounted = false;
            setIsLoading(true);
            setReloadData(false);
        }

        return cleanUp;
    }, [request, reloadData, authDispatch]);

    const reload = () => setReloadData(true);

    return [requestData, fetchError, isLoading, reload];
}

export default useGetFromAPI;