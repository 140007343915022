import { apiPrivate } from "./config";

export const getRides = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/ride`,
        params
    });
}

export const getRideTypes = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/ride/types`,
        params
    });
}

export const getRideFieldsData = () => {
    return apiPrivate.request({
        method: 'GET',
        url: `/ride/formFields`,
    });
}

export const storeRide = async (ride) => {

    try {
        const response = await apiPrivate.post('/ride', ride);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const storeRideType = async (rideType) => {

    try {
        const response = await apiPrivate.post('/ride/types', rideType);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const updateRide = async (ride) => {
    try {
        const response = await apiPrivate.patch(`/ride/${ride.id}`, ride);

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}