import { apiPrivate } from "./config";

export const getCurrencies = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/currency`,
        params
    });
}

export const storeCurrency = async (currency) => {

    try {
        const response = await apiPrivate.post('/currency', currency);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const updateCurrency = async (currency) => {
    try {
        const response = await apiPrivate.patch(`/currency/${currency.id}`, currency);

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}