import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingScreen, Alert } from "../../components";
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus, FaSearch, FaSyncAlt } from "react-icons/fa";
import { RiEditBoxLine } from "react-icons/ri";
import { getEmployees } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreateEmployeeModal, EditEmployeeModal } from './';

function Employee() {

    // Get Clients list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const getNewEmployees = useCallback(() => getEmployees(requestParams), [requestParams]);
    const [employee, employeeError, employeesAreLoading, reload] = useGetFromAPI(getNewEmployees);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    // Toggle Edit Modal
    const [showEditModal, setShowEditModal] = useState(false);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = (client) => {
        setShowEditModal(true);
        setUserSelected(client);
    };

    const [userSelected, setUserSelected] = useState(null);

    const filterEmployees = (event) => {
        event.preventDefault();
        const searchPhoneNumber = event.target.searchPhoneNumber.value;
        const searchName = event.target.searchName.value;
        const newParams = {};
        if (searchPhoneNumber) {
            newParams.phone_number = searchPhoneNumber;
        }
        if (searchName) {
            newParams.name = searchName;
        }
        setRequestParams({ ...requestParams, ...newParams })
    }

    const reloadFilters = () => {
        setRequestParams({
            page: requestParams.page
        });
    }

    return (
        <Container>
            {
                employeeError
                &&
                <Alert message={employeeError.message} />
            }
            <Card>
                {
                    employeesAreLoading
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Empleados</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <Row
                        as="form"
                        className="align-items-end justify-content-between gap-3 mb-4"
                        onSubmit={filterEmployees}
                    >
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Nombre</BtForm.Label>
                                <BtForm.Control type="text" name="searchName" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Teléfono</BtForm.Label>
                                <BtForm.Control type="text" name="searchPhoneNumber" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" className="flex-center">
                            <Button type="submit" variant="primary" className="btn-icon px-4 ms-auto">
                                <span>Buscar</span>
                                <FaSearch />
                            </Button>
                            <Button type="button" variant="primary" className="btn-icon ms-auto" onClick={reloadFilters}>
                                <span>Reiniciar</span>
                                <FaSyncAlt />
                            </Button>
                        </Col>
                    </Row>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Teléfono</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !employeesAreLoading && !employeeError
                                    &&
                                    employee.data.map((employee) => (
                                        <tr key={employee.id}>
                                            <td>{employee.id}</td>
                                            <td>{employee.name}</td>
                                            <td>{employee.last_name}</td>
                                            <td>{employee.phone_number}</td>
                                            <td>
                                                <RiEditBoxLine
                                                    onClick={() => handleShowEditModal(employee)}
                                                    className="text-primary c-pointer"
                                                    size={22.5}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !employeesAreLoading && !employeeError
                            &&
                            <Pagination className="m-0">
                                {
                                    employee.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{employee.current_page}</Pagination.Item>
                                {
                                    employee.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: employee.last_page })}>
                                            {employee.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreateEmployeeModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                reload={reload}
            />
            <EditEmployeeModal
                showModal={showEditModal}
                closeModal={handleCloseEditModal}
                client={userSelected}
                reload={reload}
            />
        </Container>
    );
}

export default Employee;