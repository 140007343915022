import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { storeRideType } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function CreateRideTypeModal({ showModal, closeModal, reload }) {

    const defaultValues = {
        name: ''
    }

    //Store a client
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const client = {
            name: values.name,
        }
        const [response, error] = await storeRideType(client);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const clientSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, '* Muy corto!')
            .max(100, '* Muy Largo!')
            .required('* Requerido'),
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Tipo de Vuelta</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={clientSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="name">
                                            Nombre
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='name'
                                            name="name"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.name}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.name}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default CreateRideTypeModal;