import axios from "axios";
import { getAuthToken } from '../../utils'

const apiPrivate = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`
    }
});

window.addEventListener('sessionStorage', () => {
    apiPrivate.defaults.headers['Authorization'] = `Bearer ${getAuthToken()}`
})

export default apiPrivate;