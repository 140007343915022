import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { storePayment } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function CreateRideModal({ showModal, closeModal, fieldsData, reload }) {

    const defaultValues = {
        client: '',
        date: '',
        paymentType: '',
        amount: '',
        bankAccount: '',
        reference: '',
    }

    //Store a Payment
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const payment = {
            client_id: values.client,
            created_at: values.date,
            payment_type_id: values.paymentType,
            amount: values.amount,
            bank_account_id: values.bankAccount,
            bank_reference: values.reference,
        }
        if (!isBankTransaction(values.paymentType)) {
            payment.bank_account_id = null;
            payment.bank_reference = '';
        }
        console.log('payment => ', payment);
        const [response, error] = await storePayment(payment);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const isBankTransaction = (paymentType) => {
        const bankPaymentTypes = ['', '1', '2']
        return bankPaymentTypes.includes(paymentType);
    };

    const paymentSchema = Yup.object().shape({
        client: Yup.string()
            .required('* Requerido'),
        date: Yup.string()
            .required('* Requerido'),
        paymentType: Yup.string()
            .required('* Requerido'),
        amount: Yup.string()
            .min(1, '* Muy corto!')
            .max(10, '* Muy Largo!')
            .required('* Requerido'),
        bankAccount: Yup.string().when('paymentType', {
            is: isBankTransaction,
            then: Yup.string().required('* Requerido')
        }),
        reference: Yup.string().when('paymentType', {
            is: isBankTransaction,
            then: Yup.string()
                .min(4, '* Muy corto!')
                .max(20, '* Muy Largo!')
                .required('* Requerido')
        })
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Cliente</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={paymentSchema}
            >
                {({ errors, values, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="client">
                                            Cliente
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='client'
                                            name="client"
                                            onChange={handleChange}
                                            isInvalid={errors.client}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.clients.map(({ id, name, last_name }) => (
                                                    <option key={id} value={id}> {name} {last_name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.client}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="date">
                                            Fecha de Pago
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="datetime-local"
                                            id='date'
                                            name="date"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.date}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.date}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="paymentType">
                                            Tipo de Pago
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='paymentType'
                                            name="paymentType"
                                            onChange={handleChange}
                                            isInvalid={errors.paymentType}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.paymentTypes.map(({ id, name, currency }) => (
                                                    <option key={id} value={id}>
                                                        {name} - {currency.name}
                                                    </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.paymentType}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="amount">
                                            Monto
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='amount'
                                            name="amount"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.amount}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.amount}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            {
                                isBankTransaction(values.paymentType)
                                &&
                                <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                                <BtForm.Label htmlFor="bankAccount">
                                                    Cuenta Bancaria
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                                    id='bankAccount'
                                                    name="bankAccount"
                                            onChange={handleChange}
                                                    isInvalid={errors.bankAccount}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                        fieldsData.bankAccounts.map(({ id, bank, cedula }) => (
                                                            <option key={id} value={id}>
                                                                {bank} - {cedula}
                                                            </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                                    {errors.bankAccount}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                                <BtForm.Label htmlFor="reference">
                                                    Referencia
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                                    id='reference'
                                                    name="reference"
                                            placeholder="..."
                                            onChange={handleChange}
                                                    isInvalid={errors.reference}
                                                />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                                    {errors.reference}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            }
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default CreateRideModal;