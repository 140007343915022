import { api, apiPrivate } from "./config";

export const login = async (loginData) => {
    try {
        const response = await api.post('/login', loginData);

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const logout = async () => {

    try {
        const response = await apiPrivate.post('/logout');

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}