import { apiPrivate } from "./config";

export const getClients = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/client`,
        params
    });
}

export const getClientsMemberships = () => {
    return apiPrivate.request({
        method: 'GET',
        url: `/client/memberships`
    });
}

export const storeClientMembership = async (membership) => {

    try {
        const response = await apiPrivate.post('/client/memberships', membership);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const storeClient = async (client) => {

    try {
        const response = await apiPrivate.post('/client', client);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const updateClient = async (client) => {
    try {
        const response = await apiPrivate.patch(`/client/${client.id}`, client);

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}