import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { storeBankAccount } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function CreateBankAccountModal({ showModal, closeModal, reload }) {

    const defaultValues = {
        bank: '',
        number: '',
        phone_number: '',
        cedula: ''
    }

    //Store
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (bankAccount) => {
        setIsLoading(true);
        const [response, error] = await storeBankAccount(bankAccount);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const paymentTypesSchema = Yup.object().shape({
        bank: Yup.string()
            .min(5, '* Muy corto!')
            .max(100, '* Muy Largo!')
            .required('* Requerido'),
        number: Yup.string()
            .matches(/^[0-9]+$/, "* Solo números")
            .min(5, '* Muy corto!')
            .max(30, '* Muy Largo!')
            .required('* Requerido'),
        phone_number: Yup.string()
            .matches(/^[0-9]+$/, "* Solo números")
            .min(8, '* Muy corto!')
            .max(14, '* Muy Largo!')
            .required('* Requerido'),
        cedula: Yup.string()
            .matches(/^[0-9]+$/, "* Solo números")
            .min(8, '* Muy corto!')
            .max(14, '* Muy Largo!')
            .required('* Requerido'),
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Cuenta Bancaria</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={paymentTypesSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="bank">
                                            Nombre del Banco
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='bank'
                                            name="bank"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.bank}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.bank}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="number">
                                            Numero de Cuenta
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='number'
                                            name="number"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.number}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.number}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="phone_number">
                                            Teléfono
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='phone_number'
                                            name="phone_number"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.phone_number}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.phone_number}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="cedula">
                                            Cedula
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='cedula'
                                            name="cedula"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.cedula}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.cedula}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >
    );
}

export default CreateBankAccountModal;