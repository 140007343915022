import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useContext } from "react";
import { FaChevronDown } from "react-icons/fa";

function AccordionItem({ children, eventKey, className }) {

    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <div
            className='d-flex align-items-center justify-content-between'
            role='button'
            onClick={decoratedOnClick}
        >
            <div className={className}>
                {children}
            </div>
            <FaChevronDown className={`rotate ${isCurrentEventKey ? 'rotate-180' : ''}`} />
        </div>
    );
}

export default AccordionItem;