import "./Sidebar.scss";
import { useRef } from 'react';
import { useOnClickOutside } from '../../hooks';
import companyLogo from "../../images/moto-vueltas-logo-tiny.png";
import Accordion from "react-bootstrap/Accordion";
import { AccordionItem } from "../";
import { Link } from "react-router-dom";
import { BsGearFill, BsBank2, BsCoin, BsCardChecklist } from "react-icons/bs";
import {
    FaUser,
    FaNetworkWired,
    FaMotorcycle,
    FaArrowAltCircleLeft,
    FaMoneyBillAlt,
    FaUserAstronaut,
    FaCashRegister,
    FaList
} from "react-icons/fa";

function Sidebar({ isOpen, closeSidebar }) {

    const contentRef = useRef();
    useOnClickOutside(contentRef, closeSidebar);

    return (
        <aside ref={contentRef} className={`sidebar ${isOpen ? "sidebar-open" : ""}`}>
            <div className="sidebar-close-btn" role="button" onClick={closeSidebar}>
                <FaArrowAltCircleLeft size={32} />
            </div>
            <div className="sidebar-header">
                <img src={companyLogo} alt="Moto vueltas Logo" />
            </div>
            <div className="divider-white"></div>
            <Accordion className="nav flex-column gap-2 px-2">
                <Link to="./clientes" className="nav-link">
                    <FaUser />
                    <span>Clientes</span>
                </Link>
                <Link to="./empleados" className="nav-link">
                    <FaUserAstronaut />
                    <span>Empleados</span>
                </Link>

                <AccordionItem eventKey="2" className="nav-link">
                    <FaMotorcycle />
                    <span>Vueltas</span>
                </AccordionItem>
                <Accordion.Collapse eventKey="2" className="px-3">
                    <div className="d-flex flex-column gap-2">
                        <Link to="./vueltas" className="nav-link">
                            <FaList />
                            <span>Registros</span>
                        </Link>
                        <Link to="./vueltas/tipos" className="nav-link">
                            <BsCardChecklist />
                            <span>Tipos</span>
                        </Link>
                    </div>
                </Accordion.Collapse>

                <Link to="./facturas" className="nav-link">
                    <FaCashRegister />
                    <span>Facturas</span>
                </Link>
                <AccordionItem eventKey="0" className="nav-link">
                    <FaMoneyBillAlt />
                    <span>Pagos</span>
                </AccordionItem>
                <Accordion.Collapse eventKey="0" className="px-3">
                    <div className="d-flex flex-column gap-2">
                        <Link to="./pagos" className="nav-link">
                            <FaList />
                            <span>Registros</span>
                        </Link>
                        <Link to="./pagos/tipos" className="nav-link">
                            <BsCardChecklist />
                            <span>Tipos</span>
                        </Link>
                    </div>
                </Accordion.Collapse>

                <AccordionItem eventKey="1" className="nav-link">
                    <BsGearFill />
                    <span>Configuración</span>
                </AccordionItem>
                <Accordion.Collapse eventKey="1" className="px-3">
                    <div className="d-flex flex-column gap-2">
                        <Link to="./cuentasBancarias" className="nav-link">
                            <BsBank2 />
                            <span>Cuentas Bancarias</span>
                        </Link>
                        <Link to="./monedas" className="nav-link">
                            <BsCoin />
                            <span>Monedas</span>
                        </Link>
                        <Link to="./clientes/membresias" className="nav-link">
                            <FaNetworkWired />
                            <span>Membresías</span>
                        </Link>
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </aside>
    );
}
export default Sidebar;