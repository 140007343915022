import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { RiEditBoxLine } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { updatePayment } from '../../api';
import { objectsDifference } from '../../utils'
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function EditRideModal({ showModal, closeModal, fieldsData, payment, reload }) {

    const defaultValues = {
        client_id: payment?.client_id.toString(),
        created_at: payment?.created_at,
        payment_type_id: payment?.payment_type_id.toString(),
        amount: payment?.amount,
        bank_account_id: payment?.bank_account_id
            ? payment.bank_account_id.toString()
            : '',
        bank_reference: payment?.bank_reference ?? '',
    }

    //Edit Payment
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        const editedPayment = objectsDifference(defaultValues, values);
        const hasChange = Object.keys(editedPayment).length > 0;

        if (!hasChange) return;

        setIsLoading(true);

        const notBankTransaction = !isBankTransaction(editedPayment?.payment_type_id);
        if (notBankTransaction) {
            editedPayment.bank_account_id = null;
            editedPayment.bank_reference = null;
        }

        editedPayment.id = payment.id;
        const [response, error] = await updatePayment(editedPayment);
        if (response) {
            closeModal();
            reload();
        }

        setStoreError(error);
        setIsLoading(false);
    }

    const isBankTransaction = (paymentType) => {
        const bankPaymentTypes = ['', '1', '2']
        return bankPaymentTypes.includes(paymentType);
    };

    //Validation Schema
    const paymentSchema = Yup.object().shape({
        client_id: Yup.string()
            .required('* Requerido'),
        created_at: Yup.string()
            .required('* Requerido'),
        payment_type_id: Yup.string()
            .required('* Requerido'),
        amount: Yup.string()
            .min(1, '* Muy corto!')
            .max(10, '* Muy Largo!')
            .required('* Requerido'),
        bank_account_id: Yup.string().when('payment_type_id', {
            is: isBankTransaction,
            then: Yup.string().required('* Requerido')
        }),
        bank_reference: Yup.string().when('payment_type_id', {
            is: isBankTransaction,
            then: Yup.string()
                .min(4, '* Muy corto!')
                .max(20, '* Muy Largo!')
                .required('* Requerido')
        })
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar Cliente</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={paymentSchema}
            >
                {({ errors, values, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="client_id">
                                            Cliente
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='client_id'
                                            name="client_id"
                                            onChange={handleChange}
                                            isInvalid={errors.client_id}
                                            defaultValue={defaultValues.client_id}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.clients.map(({ id, name, last_name }) => (
                                                    <option key={id} value={id}> {name} {last_name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.client_id}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="created_at">
                                            Fecha de Pago
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="datetime-local"
                                            id='created_at'
                                            name="created_at"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.created_at}
                                            defaultValue={defaultValues.created_at}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.created_at}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="payment_type_id">
                                            Tipo de Pago
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='payment_type_id'
                                            name="payment_type_id"
                                            onChange={handleChange}
                                            isInvalid={errors.payment_type_id}
                                            defaultValue={defaultValues.payment_type_id}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.paymentTypes.map(({ id, name, currency }) => (
                                                    <option key={id} value={id}>
                                                        {name} - {currency.name}
                                                    </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.payment_type_id}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="amount">
                                            Monto
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='amount'
                                            name="amount"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.amount}
                                            defaultValue={defaultValues.amount}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.amount}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            {
                                isBankTransaction(values.payment_type_id)
                                &&
                                <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                                <BtForm.Label htmlFor="bank_account_id">
                                                    Cuenta Bancaria
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                                    id='bank_account_id'
                                                    name="bank_account_id"
                                            onChange={handleChange}
                                                    isInvalid={errors.bank_account_id}
                                                    defaultValue={defaultValues.bank_account_id}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                        fieldsData.bankAccounts.map(({ id, bank, cedula }) => (
                                                            <option key={id} value={id}>
                                                                {bank} - {cedula}
                                                            </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                                    {errors.bank_account_id}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                                <BtForm.Label htmlFor="bank_reference">
                                                    Referencia
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                                    id='bank_reference'
                                                    name="bank_reference"
                                            placeholder="..."
                                            onChange={handleChange}
                                                    isInvalid={errors.bank_reference}
                                                    defaultValue={defaultValues.bank_reference}
                                                />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                                    {errors.bank_reference}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            }
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                        type="submit"
                                        variant="primary"
                                        className="btn-icon"
                                    >
                                        <span>Editar</span>
                                        <RiEditBoxLine size={17.5} />
                                    </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >
    );
}

export default EditRideModal;