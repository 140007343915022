import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { storeRide } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function CreateRideModal({ showModal, closeModal, fieldsData, reload }) {

    const defaultValues = {
        client: '',
        employee: '',
        from: '',
        to: '',
        rideType: '',
        amount: '',
        date: ''
    }

    //Store a Ride
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const ride = {
            client_id: values.client,
            employee_id: values.employee,
            from: values.from,
            to: values.to,
            ride_type_id: values.rideType,
            amount: values.amount,
            created_at: values.date,
        }
        const [response, error] = await storeRide(ride);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const clientSchema = Yup.object().shape({
        client: Yup.string()
            .required('* Requerido'),
        employee: Yup.string()
            .required('* Requerido'),
        from: Yup.string()
            .min(4, '* Muy corto!')
            .max(255, '* Muy Largo!')
            .required('* Requerido'),
        to: Yup.string()
            .min(4, '* Muy corto!')
            .max(255, '* Muy Largo!')
            .required('* Requerido'),
        rideType: Yup.string()
            .required('* Requerido'),
        amount: Yup.string()
            .min(1, '* Muy corto!')
            .max(10, '* Muy Largo!')
            .required('* Requerido'),
        date: Yup.string()
            .required('* Requerido'),
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Vuelta</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={clientSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="client">
                                            Cliente
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='client'
                                            name="client"
                                            onChange={handleChange}
                                            isInvalid={errors.client}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.clients.map(({ id, name, last_name }) => (
                                                    <option key={id} value={id}> {name} {last_name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.client}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="employee">
                                            Empleado
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='employee'
                                            name="employee"
                                            onChange={handleChange}
                                            isInvalid={errors.employee}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.employee.map(({ id, name, last_name }) => (
                                                    <option key={id} value={id}> {name} {last_name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.employee}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="from">
                                            Desde
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='from'
                                            name="from"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.from}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.from}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="to">
                                            Hasta
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='to'
                                            name="to"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.to}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.to}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group>
                                        <BtForm.Label htmlFor="rideType">
                                            Tipo de Vuelta
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='rideType'
                                            name="rideType"
                                            onChange={handleChange}
                                            isInvalid={errors.rideType}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.rideType.map(({ id, name }) => (
                                                    <option key={id} value={id}> {name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.rideType}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group>
                                        <BtForm.Label htmlFor="amount">
                                            Monto
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='amount'
                                            name="amount"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.amount}
                                        />
                                        <BtForm.Text className="text-muted">
                                            Solo se usaran 2 decimales
                                        </BtForm.Text>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.amount}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <BtForm.Group className="mb-3">
                                <BtForm.Label htmlFor="date">
                                    Fecha
                                </BtForm.Label>
                                <BtForm.Control
                                    type="datetime-local"
                                    id='date'
                                    name="date"
                                    placeholder="..."
                                    onChange={handleChange}
                                    isInvalid={errors.date}
                                />
                                <BtForm.Control.Feedback type="invalid" className="error-msg">
                                    {errors.date}
                                </BtForm.Control.Feedback>
                            </BtForm.Group>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default CreateRideModal;