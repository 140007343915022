import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { RiEditBoxLine } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { updateRide } from '../../api';
import { objectsDifference } from '../../utils'
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function EditRideModal({ showModal, closeModal, fieldsData, ride, reload }) {

    const defaultValues = {
        client_id: ride?.client.id.toString(),
        employee_id: ride?.employee.id.toString(),
        from: ride?.from,
        to: ride?.to,
        ride_type_id: ride?.ride_type.id.toString(),
        amount: ride?.amount
    }

    //Edit Ride
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const editedRide = objectsDifference(defaultValues, values);
        editedRide.id = ride.id;
        const [response, error] = await updateRide(editedRide);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    //Validation Schema
    const rideSchema = Yup.object().shape({
        client_id: Yup.string()
            .required('* Requerido'),
        employee_id: Yup.string()
            .required('* Requerido'),
        from: Yup.string()
            .min(4, '* Muy corto!')
            .max(255, '* Muy Largo!')
            .required('* Requerido'),
        to: Yup.string()
            .min(4, '* Muy corto!')
            .max(255, '* Muy Largo!')
            .required('* Requerido'),
        ride_type_id: Yup.string()
            .required('* Requerido'),
        amount: Yup.string()
            .min(1, '* Muy corto!')
            .max(10, '* Muy Largo!')
            .required('* Requerido'),
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar Cliente</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={rideSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="client_id">
                                            Cliente
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='client_id'
                                            name="client_id"
                                            onChange={handleChange}
                                            isInvalid={errors.client_id}
                                            defaultValue={defaultValues.client_id}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.clients.map(({ id, name, last_name }) => (
                                                    <option key={id} value={id}> {name} {last_name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.client_id}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="employee_id">
                                            Empleado
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='employee_id'
                                            name="employee_id"
                                            onChange={handleChange}
                                            isInvalid={errors.employee_id}
                                            defaultValue={defaultValues.employee_id}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.employee.map(({ id, name, last_name }) => (
                                                    <option key={id} value={id}> {name} {last_name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.employee_id}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="from">
                                            Desde
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='from'
                                            name="from"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.from}
                                            defaultValue={defaultValues.from}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.from}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="to">
                                            Hasta
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='to'
                                            name="to"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.to}
                                            defaultValue={defaultValues.to}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.to}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="ride_type_id">
                                            Tipo de Vuelta
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='ride_type_id'
                                            name="ride_type_id"
                                            onChange={handleChange}
                                            isInvalid={errors.ride_type_id}
                                            defaultValue={defaultValues.ride_type_id}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                fieldsData.rideType.map(({ id, name }) => (
                                                    <option key={id} value={id}> {name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.ride_type_id}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="amount">
                                            Monto
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='amount'
                                            name="amount"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.amount}
                                            defaultValue={defaultValues.amount}
                                        />
                                        <BtForm.Text className="text-muted">
                                            Solo se usaran 2 decimales
                                        </BtForm.Text>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.amount}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Editar</span>
                                    <RiEditBoxLine size={17.5} />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >
    );
}

export default EditRideModal;