import './scss/main.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
    BankAccount,
    Bill,
    Client,
    Currency,
    Employee,
    Login,
    Membership,
    Payment,
    PaymentType,
    Ride,
    RideType
} from './pages';
import { DashboardLayout, AuthProvider, RequireAuth } from './components';

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route element={< RequireAuth />}>
                        <Route path="/dashboard" element={< DashboardLayout />}>
                            <Route path="clientes" element={<Client />} />
                            <Route path="clientes/membresias" element={<Membership />} />
                            <Route path="cuentasBancarias" element={<BankAccount />} />
                            <Route path="empleados" element={<Employee />} />
                            <Route path="facturas" element={<Bill />} />
                            <Route path="monedas" element={<Currency />} />
                            <Route path="pagos" element={<Payment />} />
                            <Route path="pagos/tipos" element={<PaymentType />} />
                            <Route path="vueltas" element={<Ride />} />
                            <Route path="vueltas/tipos" element={<RideType />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<h1> 404 | NOT FOUND</h1>} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;

