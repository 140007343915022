import { createContext } from 'react'

export const AuthContext = createContext();

export const initialAuthState = {
    user: null,
    token: '',
};

export const types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT'
};