import { SESSION_KEY, SESSION_KEY_HASH, SESSION_DATA_HASH } from "./";
import CryptoJS from "crypto-js";

export const getAuthToken = () => {
    const localStoreValues = Object.keys(localStorage)
    for (const localIndex of localStoreValues) {
        const decryptValue = CryptoJS.AES.decrypt(localIndex, SESSION_KEY_HASH);
        const value = decryptValue.toString(CryptoJS.enc.Utf8)
        if (SESSION_KEY === value) {
            const encryptSession = localStorage.getItem(localIndex);
            const decryptSession = CryptoJS.AES.decrypt(encryptSession, SESSION_DATA_HASH);
            const sessionJson = decryptSession.toString(CryptoJS.enc.Utf8)
            const session = JSON.parse(sessionJson);
            return session.token;
        }
    }
    return '';
}