import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert as BtAlert } from 'react-bootstrap/';
import { LoadingScreen, Alert } from "../../components";
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus, FaSearch, FaSyncAlt, FaNewspaper } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { RiEditBoxLine } from "react-icons/ri";
import { getRides, getRideFieldsData, storeBill } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreateRideModal, EditRideModal } from './';

function Ride() {

    // Get Rides Fields list
    const getRideFieldsCall = useCallback(() => getRideFieldsData(), []);
    const [fieldsData, fieldsError, fieldsAreLoading] = useGetFromAPI(getRideFieldsCall);

    // Get Rides list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const getRidesCall = useCallback(() => getRides(requestParams), [requestParams]);
    const [rides, ridesError, ridesAreLoading, reload] = useGetFromAPI(getRidesCall);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    // Toggle Edit Modal
    const [rideSelected, setRideSelected] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleCloseEditModal = () => {
        setShowEditModal(null);
        setRideSelected(false);
    };
    const handleShowEditModal = (ride) => {
        setShowEditModal(true);
        setRideSelected(ride);
    };

    // Select rides for create bills
    const [selectedRides, setSelectedRides] = useState([]);
    const isRideSelected = (ride) => selectedRides.some((selectedRide) => selectedRide.id === ride.id);
    const handleSelectRide = (ride) => {
        if (isRideSelected(ride)) {
            const newSelectRides = selectedRides.filter(ele => ele.id !== ride.id);
            setSelectedRides(newSelectRides);
        } else {
            setSelectedRides([...selectedRides, ride]);
        }
    }

    // Create a bill
    const [billIsLoading, setBillIsLoading] = useState(false);
    const [billError, setBillError] = useState(null);
    const createBill = async () => {
        setBillError(null);
        const clientsSelected = selectedRides.reduce((acc, ele) => {
            if (!acc.includes(ele.client_id)) acc.push(ele.client_id);
            return acc;
        }, []);
        if (clientsSelected.length > 1) {
            setBillError('No se pueden generar una factura con mas de un cliente.');
            return;
        }
        const ridesIds = selectedRides.map(ele => ele.id);
        const clientId = selectedRides[0].client_id;
        const bill = {
            client_id: clientId,
            rides_array: ridesIds
        }
        setBillIsLoading(true);
        const [responseStore, responseError] = await storeBill(bill);
        setBillIsLoading(false);
        if (responseStore) {
            reload();
        } else {
            setBillError(responseError);
        }
    }

    const cancelBill = () => {
        setSelectedRides([]);
        setBillError(null);
    }

    const filterRides = (event) => {
        event.preventDefault();
        const newParams = {};
        for (const element of event.target) {
            if (element.value) {
                newParams[element.name] = element.value;
            }
        }
        setRequestParams({ ...requestParams, ...newParams })
    }

    const reloadFilters = () => {
        setRequestParams({
            page: requestParams.page
        });
        cancelBill();
    }

    return (
        <Container>
            {
                (ridesError || fieldsError)
                &&
                <Alert message={ridesError.message} />
            }
            <Card>
                {
                    (ridesAreLoading || fieldsAreLoading || billIsLoading)
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Vueltas</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <form onSubmit={filterRides}>
                        <Row className="align-items-end justify-content-between gap-3 mb-4">
                            <Col md="auto" lg>
                                <BtForm.Group controlId="exampleForm.ControlInput1">
                                    <BtForm.Label className="ps-2 mb-0">Buscar Cliente</BtForm.Label>
                                    <BtForm.Control type="text" name="client" placeholder="..." />
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group controlId="exampleForm.ControlInput1">
                                    <BtForm.Label className="ps-2 mb-0">Buscar Empleado</BtForm.Label>
                                    <BtForm.Control type="text" name="employee" placeholder="..." />
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group controlId="exampleForm.ControlInput1">
                                    <BtForm.Label className="ps-2 mb-0">Buscar Tipo de Vuelta</BtForm.Label>
                                    <BtForm.Select name="rideType">
                                        <option value=''> ... </option>
                                        {
                                            !fieldsAreLoading
                                            &&
                                            !fieldsError
                                            &&
                                            fieldsData.rideType.map(({ id, name }) => (
                                                <option key={id}> {name} </option>
                                            ))
                                        }
                                    </BtForm.Select>
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group controlId="exampleForm.ControlInput1">
                                    <BtForm.Label className="ps-2 mb-0">Buscar Factura</BtForm.Label>
                                    <BtForm.Control type="text" name="bill" placeholder="..." />
                                </BtForm.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-end justify-content-between gap-3 mb-4">
                            <Col md="auto" lg>
                                <BtForm.Group controlId="exampleForm.ControlInput1">
                                    <BtForm.Label className="ps-2 mb-0">Buscar Desde La Fecha</BtForm.Label>
                                    <BtForm.Control type="datetime-local" name="since" />
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group controlId="exampleForm.ControlInput1">
                                    <BtForm.Label className="ps-2 mb-0">Buscar Hasta La Fecha</BtForm.Label>
                                    <BtForm.Control type="datetime-local" name="until" />
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" className="flex-center">
                                <Button type="submit" variant="primary" className="btn-icon px-4 ms-auto">
                                    <span>Buscar</span>
                                    <FaSearch />
                                </Button>
                                <Button type="button" variant="primary" className="btn-icon ms-auto" onClick={reloadFilters}>
                                    <span>Reiniciar</span>
                                    <FaSyncAlt />
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    <div className="table-container">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Empleado</th>
                                    <th>Tipo de Vuelta</th>
                                    <th>Desde</th>
                                    <th>Hasta</th>
                                    <th>Monto</th>
                                    <th>Factura</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !ridesAreLoading && !ridesError
                                    &&
                                    rides.data.map((ride) => (
                                        <tr key={ride.id}>
                                            <td>{ride.id}</td>
                                            <td>{ride.created_at}</td>
                                            <td>
                                                {ride.client.name} {ride.client.last_name}
                                            </td>
                                            <td>
                                                {ride.employee.name} {ride.employee.last_name}
                                            </td>
                                            <td>{ride.ride_type.name}</td>
                                            <td>{ride.from}</td>
                                            <td>{ride.to}</td>
                                            <td>{ride.amount}$</td>
                                            <td>{ride.bill_id ? '#' : ''}{ride.bill_id}</td>
                                            <td>
                                                <div className="flex-center justify-content-around w-100 h-100">
                                                    {
                                                        !ride.bill_id
                                                        &&
                                                        <>
                                                            <RiEditBoxLine
                                                                onClick={() => handleShowEditModal(ride)}
                                                                className="text-primary c-pointer mt-1"
                                                                size={20}
                                                            />
                                                            <input
                                                                onChange={() => handleSelectRide(ride)}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={isRideSelected(ride)}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <p className="d-flex justify-content-end mb-3">
                        {
                            !ridesAreLoading && !ridesError && rides?.totalAmount
                            &&
                            <>Monto total de vueltas: {rides.totalAmount} USD</>
                        }
                    </p>
                    <div className="d-flex justify-content-end">
                        {
                            !ridesAreLoading && !ridesError
                            &&
                            <Pagination className="m-0">
                                {
                                    rides.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{rides.current_page}</Pagination.Item>
                                {
                                    rides.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: rides.last_page })}>
                                            {rides.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-4">
                        {
                            billError
                            &&
                            <BtAlert variant='danger' className="my-0 py-1 me-auto">
                                {billError}
                            </BtAlert>
                        }
                        {
                            selectedRides.length > 0
                            &&
                            <>
                                <Button variant="secondary" className="btn-icon" onClick={cancelBill}>
                                    <span>Cancelar</span>
                                    <MdClose size={20} />
                                </Button>
                                <Button variant="primary" className="btn-icon" onClick={createBill}>
                                    <span>Facturar</span>
                                    <FaNewspaper size={20} />
                                </Button>
                            </>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreateRideModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                fieldsData={fieldsData}
                reload={reload}
            />
            <EditRideModal
                showModal={showEditModal}
                closeModal={handleCloseEditModal}
                fieldsData={fieldsData}
                ride={rideSelected}
                reload={reload}
            />
        </Container>
    );
}

export default Ride;