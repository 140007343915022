import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingScreen, Alert } from "../../components";
import { Form as BtForm } from 'react-bootstrap/';
import { FaSearch, FaSyncAlt } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import { getBills, getRides } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { InfoBillModal } from './';

function Bill() {

    // Get Bill list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const getDataCall = useCallback(() => getBills(requestParams), [requestParams]);
    const [data, dataError, dataIsLoading] = useGetFromAPI(getDataCall);

    // Toggle Info Modal
    const [itemSelected, setItemSelected] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);
    console.log(showInfoModal)

    const handleCloseInfoModal = () => {
        setShowInfoModal(false);
        setItemSelected(null);
    };
    const handleShowInfoModal = (item) => {
        setShowInfoModal(true);
        setItemSelected(item);
    };

    const filters = (event) => {
        event.preventDefault();
        const newParams = {};
        for (const element of event.target) {
            if (element.value) {
                newParams[element.name] = element.value;
            }
        }
        setRequestParams({ ...requestParams, ...newParams })
    }

    const reloadFilters = () => {
        setRequestParams({
            page: requestParams.page
        });
    }

    return (
        <Container>
            {
                dataError
                &&
                <Alert message={dataError.message} />
            }
            <Card>
                {
                    dataIsLoading
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Facturas</h3>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row
                        as="form"
                        className="align-items-end justify-content-between gap-3 mb-4"
                        onSubmit={filters}
                    >
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Cliente</BtForm.Label>
                                <BtForm.Control type="text" name="client" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Vuelta</BtForm.Label>
                                <BtForm.Control type="text" name="ride" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" lg>
                            <BtForm.Group controlId="exampleForm.ControlInput1">
                                <BtForm.Label className="ps-2 mb-0">Buscar Monto</BtForm.Label>
                                <BtForm.Control type="text" name="amount" placeholder="..." />
                            </BtForm.Group>
                        </Col>
                        <Col md="auto" className="flex-center">
                            <Button type="submit" variant="primary" className="btn-icon px-4 ms-auto">
                                <span>Buscar</span>
                                <FaSearch />
                            </Button>
                            <Button type="button" variant="primary" className="btn-icon ms-auto" onClick={reloadFilters}>
                                <span>Reiniciar</span>
                                <FaSyncAlt />
                            </Button>
                        </Col>
                    </Row>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Monto</th>
                                    <th>Vueltas</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !dataIsLoading && !dataError
                                    &&
                                    data.data.map((bill) => (
                                        <tr key={bill.id}>
                                            <td>{bill.id}</td>
                                            <td>{bill.created_at}</td>
                                            <td>
                                                {bill.client.name} {bill.client.last_name}
                                            </td>
                                            <td>{bill.amount}</td>
                                            <td>{bill.rides.map(ride => (`#${ride.id} `))}</td>
                                            <td>
                                                <MdInfoOutline
                                                    onClick={() => handleShowInfoModal(bill)}
                                                    className="text-info c-pointer"
                                                    size={22.5}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !dataIsLoading && !dataError
                            &&
                            <Pagination className="m-0">
                                {
                                    data.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{data.current_page}</Pagination.Item>
                                {
                                    data.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: data.last_page })}>
                                            {data.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <InfoBillModal
                showModal={showInfoModal}
                closeModal={handleCloseInfoModal}
                bill={itemSelected}
            />
        </Container>
    );
}

export default Bill;