import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import { LoadingScreen, Alert } from "../../components";
import { FaPlus } from "react-icons/fa";
import { RiEditBoxLine } from "react-icons/ri";
import { getCurrencies } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreateCurrencyModal, EditCurrencyModal } from './';

function Currency() {

    // Get Currency list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const getNewCurrencies = useCallback(() => getCurrencies(requestParams), [requestParams]);
    const [currencies, currencyError, currenciesAreLoading, reload] = useGetFromAPI(getNewCurrencies);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    // Toggle Edit Modal
    const [itemSelected, setItemSelected] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setItemSelected(null);
    };
    const handleShowEditModal = (item) => {
        setShowEditModal(true);
        setItemSelected(item);
    };

    return (
        <Container>
            {
                currencyError
                &&
                <Alert message={currencyError.message} />
            }
            <Card>
                {
                    currenciesAreLoading
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Monedas</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Ultima Actualización</th>
                                    <th>Cambio</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !currenciesAreLoading && !currencyError
                                    &&
                                    currencies.data.map((currency) => (
                                        <tr key={currency.id}>
                                            <td>{currency.id}</td>
                                            <td>{currency.name}</td>
                                            <td>{currency.updated_at}</td>
                                            <td>{currency.change}</td>
                                            <td>
                                                {
                                                    currency.name !== 'VES'
                                                    &&
                                                    <RiEditBoxLine
                                                        onClick={() => handleShowEditModal(currency)}
                                                        className="text-primary c-pointer"
                                                        size={22.5}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !currenciesAreLoading && !currencyError
                            &&
                            <Pagination className="m-0">
                                {
                                    currencies.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{currencies.current_page}</Pagination.Item>
                                {
                                    currencies.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: currencies.last_page })}>
                                            {currencies.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreateCurrencyModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                reload={reload}
            />

            <EditCurrencyModal
                showModal={showEditModal}
                currency={itemSelected}
                closeModal={handleCloseEditModal}
                reload={reload}
            />
        </Container>
    );
}

export default Currency;