import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { storePaymentType } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function CreateCurrencyModal({ showModal, closeModal, currencies, reload }) {

    const defaultValues = {
        name: '',
        currency_id: '',
    }

    //Store
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const client = {
            name: values.name,
            currency_id: values.currency_id,
        }
        const [response, error] = await storePaymentType(client);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const paymentTypesSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, '* Muy corto!')
            .max(100, '* Muy Largo!')
            .required('* Requerido'),
        currency_id: Yup.string()
            .required('* Requerido'),
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Tipo de Pago</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={paymentTypesSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <BtForm.Group className="mb-3">
                                <BtForm.Label htmlFor="name">
                                    Nombre
                                </BtForm.Label>
                                <BtForm.Control
                                    type="text"
                                    id='name'
                                    name="name"
                                    placeholder="..."
                                    onChange={handleChange}
                                    isInvalid={errors.name}
                                />
                                <BtForm.Control.Feedback type="invalid" className="error-msg">
                                    {errors.name}
                                </BtForm.Control.Feedback>
                            </BtForm.Group>
                            <BtForm.Group className="mb-3">
                                <BtForm.Label htmlFor="currency_id">
                                    Moneda
                                </BtForm.Label>
                                <BtForm.Select
                                    type="text"
                                    id='currency_id'
                                    name="currency_id"
                                    onChange={handleChange}
                                    isInvalid={errors.currency_id}
                                >
                                    <option value=''> ... </option>
                                    {
                                        currencies.map(({ id, name }) => (
                                            <option key={id} value={id}> {name} </option>
                                        ))
                                    }
                                </BtForm.Select>
                                <BtForm.Control.Feedback type="invalid" className="error-msg">
                                    {errors.currency_id}
                                </BtForm.Control.Feedback>
                            </BtForm.Group>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal >
    );
}

export default CreateCurrencyModal;