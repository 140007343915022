import { types, initialAuthState } from './';

const authReducer = (state, action) => {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state, ...action.payload
            }

        case types.LOGOUT:
            return {
                ...state, ...initialAuthState
            }

        default:
            return state;
    }
}

export default authReducer;