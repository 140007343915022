import { useState } from "react";
import CryptoJS from "crypto-js";
import { SESSION_KEY, SESSION_KEY_HASH, SESSION_DATA_HASH } from "../utils/constants";

const useSessionPersistent = (initialValue = {}) => {

    const KEY = CryptoJS.AES.encrypt(SESSION_KEY, SESSION_KEY_HASH).toString();

    const [session, setSessionValue] = useState(() => {
        try {
            const item = getSessionData(SESSION_KEY, SESSION_KEY_HASH);
            if (!item) return initialValue;
            const decryptItem = CryptoJS.AES.decrypt(item, SESSION_DATA_HASH);
            const itemValue = decryptItem.toString(CryptoJS.enc.Utf8)
            return JSON.parse(itemValue);
        } catch (error) {
            return initialValue;
        }
    });

    const setSession = (value) => {
        try {
            localStorage.clear();
            setSessionValue(value);
            const jsonValue = JSON.stringify(value);
            const sessionValue = CryptoJS.AES.encrypt(jsonValue, SESSION_DATA_HASH).toString();
            window.localStorage.setItem(KEY, sessionValue);
            window.dispatchEvent(new Event("sessionStorage"));
        } catch (error) {
            console.error(error);
        }
    }

    const closeSession = () => {
        setSessionValue(initialValue);
        localStorage.clear();
    }

    return { session, setSession, closeSession };
}

const getSessionData = (SESSION_KEY, SESSION_KEY_HASH) => {
    const localStoreValues = Object.keys(localStorage)
    for (const localIndex of localStoreValues) {
        const decryptValue = CryptoJS.AES.decrypt(localIndex, SESSION_KEY_HASH);
        const value = decryptValue.toString(CryptoJS.enc.Utf8)
        if (SESSION_KEY === value) {
            return localStorage.getItem(localIndex);
        }
    }
    return '';
}

export default useSessionPersistent;