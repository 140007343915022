import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import { LoadingScreen, Alert } from "../../components";
import { FaPlus } from "react-icons/fa";
import { getBankAccount } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreateBankAccountModal } from '.';

function BankAccount() {

    // Get Bank Accounts list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const bankAccountCall = useCallback(() => getBankAccount(requestParams), [requestParams]);
    const [accounts, accountError, accountsAreLoading, reload] = useGetFromAPI(bankAccountCall);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    return (
        <Container>
            {
                accountError
                &&
                <Alert message={accountError.message} />
            }
            <Card>
                {
                    accountsAreLoading
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Cuentas Bancarias</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Banco</th>
                                    <th>Num. Cuenta</th>
                                    <th>Teléfono</th>
                                    <th>Cedula</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !accountsAreLoading
                                    &&
                                    !accountError
                                    &&
                                    accounts.data.map((type) => (
                                        <tr key={type.id}>
                                            <td>{type.id}</td>
                                            <td>{type.bank}</td>
                                            <td>{type.number}</td>
                                            <td>{type.phone_number}</td>
                                            <td>{type.cedula}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !accountsAreLoading && !accountError
                            &&
                            <Pagination className="m-0">
                                {
                                    accounts.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{accounts.current_page}</Pagination.Item>
                                {
                                    accounts.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: accounts.last_page })}>
                                            {accounts.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreateBankAccountModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                reload={reload}
            />
        </Container>
    );
}

export default BankAccount;