import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingScreen, Alert } from "../../components";
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus, FaSearch, FaSyncAlt } from "react-icons/fa";
import { RiEditBoxLine } from "react-icons/ri";
import { getPayments, getPaymentFieldsData } from '../../api';
import { useState, useCallback } from "react";
import { useGetFromAPI } from '../../hooks';
import { CreatePaymentModal, EditPaymentModal } from '.';

function Payment() {

    const getPaymentFieldsCall = useCallback(() => getPaymentFieldsData(), []);
    const [fieldsData, fieldsError, fieldsAreLoading] = useGetFromAPI(getPaymentFieldsCall);

    // Get Payment list
    const [requestParams, setRequestParams] = useState({ page: 1 });
    const getDataCall = useCallback(() => getPayments(requestParams), [requestParams]);
    const [data, dataError, dataIsLoading, reload] = useGetFromAPI(getDataCall);

    // Toggle Create Modal
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => setShowCreateModal(true);

    // Toggle Edit Modal
    const [showEditModal, setShowEditModal] = useState(false);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = (item) => {
        setShowEditModal(true);
        setItemSelected(item);
    };

    const [itemSelected, setItemSelected] = useState(null);

    const filters = (event) => {
        event.preventDefault();
        const newParams = {};
        for (const element of event.target) {
            if (element.value) {
                newParams[element.name] = element.value;
            }
        }
        console.log(newParams);
        setRequestParams({ ...requestParams, ...newParams })
    }

    const reloadFilters = () => {
        setRequestParams({
            page: requestParams.page
        });
    }

    return (
        <Container>
            {
                dataError
                &&
                fieldsError
                &&
                <Alert message={dataError.message} />
            }
            <Card>
                {
                    dataIsLoading
                    &&
                    fieldsAreLoading
                    &&
                    <LoadingScreen />
                }
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">
                        <h3 className="m-0">Pagos</h3>
                    </Card.Title>
                    <Button variant="primary" className="btn-icon" onClick={handleShowCreateModal}>
                        <span>Registrar</span>
                        <FaPlus />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <form onSubmit={filters}>
                        <Row className="align-items-end justify-content-between gap-3 mb-4">
                            <Col md="auto" lg>
                                <BtForm.Group>
                                    <BtForm.Label className="ps-2 mb-0">Buscar Tipo de Pago</BtForm.Label>
                                    <BtForm.Select name="paymentType">
                                        <option value=''> ... </option>
                                        {
                                            !fieldsAreLoading
                                            &&
                                            !fieldsError
                                            &&
                                            fieldsData.paymentTypes.map(({ id, name, currency }) => (
                                                <option key={id} value={id}>
                                                    {name} - {currency.name}
                                                </option>
                                            ))
                                        }
                                    </BtForm.Select>
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group>
                                    <BtForm.Label className="ps-2 mb-0">Buscar Cuenta</BtForm.Label>
                                    <BtForm.Select name="bankAccount">
                                        <option value=''> ... </option>
                                        {
                                            !fieldsAreLoading
                                            &&
                                            !fieldsError
                                            &&
                                            fieldsData.bankAccounts.map(({ id, bank, cedula }) => (
                                                <option key={id} value={id}>
                                                    {bank} - {cedula}
                                                </option>
                                            ))
                                        }
                                    </BtForm.Select>
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group>
                                    <BtForm.Label className="ps-2 mb-0">Buscar Referencia</BtForm.Label>
                                    <BtForm.Control type="text" name="bankReference" placeholder="..." />
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" lg>
                                <BtForm.Group>
                                    <BtForm.Label className="ps-2 mb-0">Buscar Monto</BtForm.Label>
                                    <BtForm.Control type="text" name="amount" placeholder="..." />
                                </BtForm.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-end justify-content-between gap-3 mb-4">
                            <Col md="auto" lg>
                                <BtForm.Group>
                                    <BtForm.Label className="ps-2 mb-0">Buscar Cliente</BtForm.Label>
                                    <BtForm.Control type="text" name="client" placeholder="..." />
                                </BtForm.Group>
                            </Col>
                            <Col md="auto" className="flex-center">
                                <Button type="submit" variant="primary" className="btn-icon px-4 ms-auto">
                                    <span>Buscar</span>
                                    <FaSearch />
                                </Button>
                                <Button type="button" variant="primary" className="btn-icon ms-auto" onClick={reloadFilters}>
                                    <span>Reiniciar</span>
                                    <FaSyncAlt />
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    <div className="table-container mb-4">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Tipo de Pago</th>
                                    <th>Banco</th>
                                    <th>Referencia</th>
                                    <th>Monto</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !dataIsLoading && !dataError
                                    &&
                                    data.data.map((payment) => (
                                        <tr key={payment.id}>
                                            <td>{payment.id}</td>
                                            <td>{payment.created_at}</td>
                                            <td>
                                                {payment.client.name} {payment.client.last_name}
                                            </td>
                                            <td>
                                                {payment.payment_type.name} - {payment.payment_type.currency.name}
                                            </td>
                                            <td>
                                                {payment.bank_account?.bank} - {payment.bank_account?.cedula}
                                            </td>
                                            <td>
                                                {payment.bank_reference}
                                            </td>
                                            <td>
                                                {payment.amount} {payment.payment_type.currency.name}
                                            </td>
                                            <td>
                                                <RiEditBoxLine
                                                    onClick={() => handleShowEditModal(payment)}
                                                    className="text-primary c-pointer"
                                                    size={22.5}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            !dataIsLoading && !dataError
                            &&
                            <Pagination className="m-0">
                                {
                                    data.current_page > 1
                                    &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page - 1) })}
                                        />
                                        <Pagination.Item
                                            onClick={() => setRequestParams({ ...requestParams, page: 1 })}
                                        >
                                            {1}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                }
                                <Pagination.Item active>{data.current_page}</Pagination.Item>
                                {
                                    data.last_page > requestParams.page
                                    &&
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => setRequestParams({ ...requestParams, page: data.last_page })}>
                                            {data.last_page}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={() => setRequestParams({ ...requestParams, page: (requestParams.page + 1) })} />
                                    </>
                                }
                            </Pagination>
                        }
                    </div>
                </Card.Body>
            </Card>
            <CreatePaymentModal
                showModal={showCreateModal}
                closeModal={handleCloseCreateModal}
                fieldsData={fieldsData}
                reload={reload}
            />
            <EditPaymentModal
                showModal={showEditModal}
                closeModal={handleCloseEditModal}
                fieldsData={fieldsData}
                payment={itemSelected}
                reload={reload}
            />
        </Container>
    );
}

export default Payment;