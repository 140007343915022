import { apiPrivate } from "./config";

export const getEmployees = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/employee`,
        params
    });
}

export const storeEmployee = async (employee) => {

    try {
        const response = await apiPrivate.post('/employee', employee);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const updateEmployee = async (employee) => {
    try {
        const response = await apiPrivate.patch(`/employee/${employee.id}`, employee);

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}