import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { Form as BtForm } from 'react-bootstrap/';
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { storeClient } from '../../api';
import { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function CreateClientModal({ showModal, closeModal, memberships, reload }) {

    const defaultValues = {
        name: '',
        last_name: '',
        phone_number: '',
        membership: '',
        address: ''
    }

    //Store a client
    const [isLoading, setIsLoading] = useState(false);
    const [storeError, setStoreError] = useState(null);
    const handleSubmit = async (values) => {
        console.log(values)
        setIsLoading(true);
        const client = {
            name: values.name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            client_membership_id: values.membership,
            address: values.address ?? ''
        }
        const [response, error] = await storeClient(client);
        if (response) {
            closeModal();
            reload();
        }
        setStoreError(error);
        setIsLoading(false);
    }

    const clientSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, '* Muy corto!')
            .max(100, '* Muy Largo!')
            .required('* Requerido'),
        last_name: Yup.string()
            .min(2, '* Muy corto!')
            .max(100, '* Muy Largo!')
            .required('* Requerido'),
        phone_number: Yup.string()
            .min(8, '* Muy corto!')
            .max(14, '* Muy Largo!')
            .required('* Requerido'),
        membership: Yup.string()
            .required('* Requerido'),
        address: Yup.string()
            .max(255, '* Muy Largo!')
    });

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registrar Cliente</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={defaultValues}
                onSubmit={handleSubmit}
                validationSchema={clientSchema}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <Modal.Body>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="name">
                                            Nombre
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='name'
                                            name="name"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.name}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.name}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="last_name">
                                            Apellido
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='last_name'
                                            name="last_name"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.last_name}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.last_name}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="phone_number">
                                            Teléfono
                                        </BtForm.Label>
                                        <BtForm.Control
                                            type="text"
                                            id='phone_number'
                                            name="phone_number"
                                            placeholder="..."
                                            onChange={handleChange}
                                            isInvalid={errors.phone_number}
                                        />
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.phone_number}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                                <Col lg>
                                    <BtForm.Group className="mb-3">
                                        <BtForm.Label htmlFor="membership">
                                            Membresía
                                        </BtForm.Label>
                                        <BtForm.Select
                                            type="text"
                                            id='membership'
                                            name="membership"
                                            onChange={handleChange}
                                            isInvalid={errors.membership}
                                        >
                                            <option value=''> ... </option>
                                            {
                                                memberships.map(({ id, name }) => (
                                                    <option key={id} value={id}> {name} </option>
                                                ))
                                            }
                                        </BtForm.Select>
                                        <BtForm.Control.Feedback type="invalid" className="error-msg">
                                            {errors.membership}
                                        </BtForm.Control.Feedback>
                                    </BtForm.Group>
                                </Col>
                            </Row>
                            <BtForm.Group className="mb-3">
                                <BtForm.Label htmlFor="address">
                                    Dirección
                                </BtForm.Label>
                                <BtForm.Control
                                    as="textarea"
                                    id='address'
                                    name="address"
                                    placeholder="..."
                                    onChange={handleChange}
                                    isInvalid={errors.address}
                                />
                                <BtForm.Control.Feedback type="invalid" className="error-msg">
                                    {errors.address}
                                </BtForm.Control.Feedback>
                            </BtForm.Group>
                            {
                                !isLoading
                                &&
                                storeError
                                &&
                                <BtAlert variant="danger">
                                    {storeError}
                                </BtAlert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                className="btn-icon px-4"
                                onClick={closeModal}
                            >
                                <span>Cerrar</span>
                                <MdClose size={20} />
                            </Button>
                            {
                                !isLoading
                                &&
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Registrar</span>
                                    <FaPlus />
                                </Button>
                            }
                            {
                                isLoading
                                &&
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-icon"
                                >
                                    <span>Enviando</span>
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default CreateClientModal;