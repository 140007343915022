import './LoadingScreen.scss';
import Spinner from 'react-bootstrap/Spinner';

function LoadingScreen() {
    return (
        <div className="spinner-container">
            <Spinner animation="border" variant="primary" size='lg' />
        </div>
    );
}

export default LoadingScreen;