import { apiPrivate } from "./config";

export const getBankAccount = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/bankAccount`,
        params
    });
}

export const storeBankAccount = async (bankAccount) => {

    try {
        const response = await apiPrivate.post('/bankAccount', bankAccount);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}