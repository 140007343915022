import { apiPrivate } from "./config";

export const getPayments = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/payment`,
        params
    });
}

export const getPaymentFieldsData = () => {
    return apiPrivate.request({
        method: 'GET',
        url: `/payment/formFields`,
    });
}

export const getPaymentTypes = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/payment/type`,
        params
    });
}

export const storePayment = async (payment) => {

    try {
        const response = await apiPrivate.post('/payment', payment);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const storePaymentType = async (paymentType) => {

    try {
        const response = await apiPrivate.post('/payment/type', paymentType);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}

export const updatePayment = async (payment) => {
    try {
        const response = await apiPrivate.patch(`/payment/${payment.id}`, payment);

        if (response.status === 200) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}