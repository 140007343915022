import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../contexts';

const RequireAuth = () => {
    const { authState } = useAuthContext();
    const location = useLocation();

    return (
        authState?.user
            ? <Outlet />
            : <Navigate to='/' state={{ from: location }} replace />
    );
}

export default RequireAuth;