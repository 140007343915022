import Table from "react-bootstrap/Table";
import Modal from 'react-bootstrap/Modal';

function ClientDebtHistory({ showModal, closeModal, client }) {

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            size='xl'
        >
            <Modal.Header closeButton>
                <Modal.Title>Historial de Deudas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Teléfono</th>
                            <th>Dirección</th>
                            <th>Membresía</th>
                            <th>Balance</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            client?.debtHistory?.map((ride) => (
                                <tr key={ride.id}>
                                    <td>{ride.id}</td>
                                    <td>{ride.created_at}</td>
                                    <td>
                                        {ride.client.name} {ride.client.last_name}
                                    </td>
                                    <td>
                                        {ride.employee.name} {ride.employee.last_name}
                                    </td>
                                    <td>{ride.ride_type.name}</td>
                                    <td>{ride.from}</td>
                                    <td>{ride.to}</td>
                                    <td>{ride.amount}$</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <div className="d-flex flex-column align-items-end gap-2 px-3">
                    <div> Saldo a favor: +{client?.positiveBalance}</div>
                    <div> Deuda total: {client?.balance} </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ClientDebtHistory;