import { AuthContext, authReducer, initialAuthState } from './../../contexts';
import { useReducer } from 'react';
import { useSessionPersistent } from '../../hooks';

const AuthProvider = ({ children }) => {
    const { session, setSession, closeSession } = useSessionPersistent(initialAuthState);
    const [authState, authDispatch] = useReducer(authReducer, session);

    if (!session.user && authState.user) {
        setSession(authState);
    }

    if (session.user && !authState.user) {
        closeSession();
    }

    return (
        <AuthContext.Provider value={{ authState, authDispatch }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;