import { apiPrivate } from "./config";

export const getBills = (params) => {
    return apiPrivate.request({
        method: 'GET',
        url: `/bill`,
        params
    });
}

export const storeBill = async (Bill) => {

    try {
        const response = await apiPrivate.post('/bill', Bill);

        if (response.status === 201) {
            return [response.data, null];
        }

    } catch (error) {

        if (error.response?.data) {
            const errorMsg = error.response?.data?.message;
            return [null, errorMsg];
        }

        return [null, 'Ups ocurrió un problema'];
    }
}