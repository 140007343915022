import "./Navbar.scss";
import { Navbar as BtNavbar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { MdOutlineLogout, MdClose } from "react-icons/md";
import { FaBars, FaUserTie } from "react-icons/fa";
import { useAuthContext } from '../../contexts';
import { logout } from "../../api/AuthAPI";
import { useState } from "react";

function Navbar({ openSidebar }) {

    const { authState, authDispatch } = useAuthContext();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isLoading, setIsLoading] = useState(false);
    const [logoutError, setLogoutError] = useState(null);
    const handleLogout = async () => {
        setIsLoading(true);
        const [response, error] = await logout();
        setIsLoading(false);
        if (response) {
            authDispatch({ type: 'LOGOUT' });
        }
        setLogoutError(error);
    }

    return (
        <BtNavbar className="navbar">
            <div className="user-name">
                <FaUserTie size={24} />
                <div className="h5 m-0">
                    {authState?.user.name}
                </div>
            </div>
            <div className="sidebar-button" role="button" onClick={openSidebar}>
                <FaBars size={28} />
            </div>
            <div className="flex-center" role="button" onClick={handleShow}>
                <span className="h5 m-0">Salir</span>
                <MdOutlineLogout size={28} />
            </div>
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header className="flex-center">
                    <Modal.Title>
                        <h2 className="mb-0">¿Seguro que desea salir?</h2>
                    </Modal.Title>
                </Modal.Header>
                {
                    !isLoading
                    &&
                    logoutError
                    &&
                    <BtAlert variant="danger">
                        {logoutError}
                    </BtAlert>
                }
                <Modal.Footer className="flex-center">
                    <Button variant="secondary" className="btn-icon px-2" onClick={handleClose}>
                        <span> Cancelar</span>
                        <MdClose size={20} />
                    </Button>
                    {
                        !isLoading
                        &&
                        <Button
                            type="submit"
                            variant="primary"
                            className="btn-icon px-4"
                            onClick={handleLogout}
                        >
                            <span>Salir</span>
                            <MdOutlineLogout />
                        </Button>
                    }
                    {
                        isLoading
                        &&
                        <Button
                            type="button"
                            variant="primary"
                            className="btn-icon px-2"
                        >
                            <span>Saliendo</span>
                            <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                            />
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </BtNavbar >
    );
}

export default Navbar;