import "./Login.scss";
import { Formik, Form, Field } from 'formik';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Alert as BtAlert } from 'react-bootstrap/';
import { useAuthContext } from '../../contexts';
import { useNavigate } from "react-router-dom";
import { login } from '../../api';
import { useState } from "react";

function Login() {

    const { authDispatch } = useAuthContext();

    const navigate = useNavigate();

    const invalidFieldClass = (error) => error ? 'is-invalid-field' : '';

    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const handleSubmit = async (values) => {
        setLoginError(null);
        setIsLoading(true);
        const [response, error] = await login(values);
        setIsLoading(false);
        if (response) {
            const { user, token } = response;
            const payload = { user, token };
            authDispatch({ type: 'LOGIN', payload });
            navigate('/dashboard/clientes');
        }
        setLoginError(error);
    }

    const validateForm = (values) => {
        const errors = {};
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(values.email)) errors.email = '* Dirección de correo invalida';
        for (const field in values) {
            if (values[field].trim() === '') {
                errors[field] = '* Campo Requerido';
            }
        }
        return errors;
    }

    return (
        <div className='container-login'>
            <Formik
                initialValues={{ email: '', password: '' }}
                validate={validateForm}
                onSubmit={handleSubmit}
            >
                {({ errors }) => (
                    <Form className='card p-4 rounded-0 shadow'>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="email">
                                Correo Electrónico
                            </label>
                            <Field type='email' id='email' name='email' className={`form-control ${invalidFieldClass(errors.email)}`} />
                            <div className="error-msg">
                                {errors.email}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="password">
                                Contraseña
                            </label>
                            <Field type='password' id='password' name='password' className={`form-control ${invalidFieldClass(errors.password)}`} />
                            <div className="error-msg">
                                {errors.password}
                            </div>
                        </div>
                        {
                            !isLoading
                            &&
                            <Button
                                type="submit"
                                variant="primary"
                                className="btn-icon px-4 mb-2"
                            >
                                <span>Ingresar</span>
                            </Button>
                        }
                        {
                            isLoading
                            &&
                            <Button
                                type="button"
                                variant="primary"
                                className="btn-icon px-2 mb-2"
                            >
                                <span>Cargando</span>
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    role="status"
                                />
                            </Button>
                        }
                        {
                            !isLoading
                            &&
                            loginError
                            &&
                            <BtAlert variant="danger">
                                {loginError}
                            </BtAlert>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Login;
